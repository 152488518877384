import React from "react";
import { navigate } from "gatsby";
import "./src/styles/style.scss";

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <link
      rel="preload"
      href="/fonts/SuisseIntl-Regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="custom-font"
    />,
  ]);
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  const currentParams = new URLSearchParams(location.search);
  const previousParams = new URLSearchParams(prevLocation?.search ?? "");

  const hadContentOnly = previousParams.get("content_only") === "true";
  const hasContentOnly = currentParams.get("content_only") === "true";

  if (!hasContentOnly && hadContentOnly) {
    const hash = location.hash || "";

    currentParams.set("content_only", "true");

    const newSearch = currentParams.toString();
    const newPath = `${location.pathname}?${newSearch}${hash}`;

    navigate(newPath, { replace: true });
  }
};
