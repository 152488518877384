import React, { FC, ReactNode } from "react";
import { GatsbyLinkProps } from "gatsby";
import { Link as I18nLink, useI18next } from "gatsby-plugin-react-i18next";
import { useViewVariant } from "@app/hooks/use-view-variant/useViewVariant";
import {
  handleLinkClick,
  handleStrippedLinkClick,
} from "@app/utils/anchor-scroll";

export interface LinkProps extends GatsbyLinkProps<unknown> {
  language?: string;
  to: string;
  title?: string;
  className?: string;
  stripHash?: boolean;
  onAnchorLinkClick?: () => void;
  children?: ReactNode;
  isOpenInNewTab?: boolean;
}

export const Link: FC<LinkProps> = ({
  to: _to,
  children,
  stripHash = true,
  onAnchorLinkClick,
  isOpenInNewTab,
  ...rest
}) => {
  let to = _to;
  const { languages, language, defaultLanguage, originalPath } = useI18next();
  const { isContentOnly } = useViewVariant();

  const isBrowser = typeof window !== "undefined";
  const currentSearch = isBrowser ? window.location.search : "";

  const langPrefix = languages.find((l) => to.startsWith(`/${l}/`));

  if (langPrefix) {
    to = to.replace(`/${langPrefix}`, "");
  }

  // External link
  if (to.startsWith("http") || isOpenInNewTab) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    );
  }

  // Detect if we need to add content_only
  const currentParams = new URLSearchParams(currentSearch);
  const shouldAddContentOnly = currentParams.get("content_only") === "true";

  // Split to preserve anchor
  const [basePathWithQuery, hash] = to.split("#");
  const url = new URLSearchParams(basePathWithQuery.split("?")[1] || "");
  const path = basePathWithQuery.split("?")[0];

  if (shouldAddContentOnly || isContentOnly) {
    url.set("content_only", "true");
  }

  const fullPath =
    `${path}${url.toString() ? "?" + url.toString() : ""}` +
    (hash ? `#${hash}` : "");

  // Pure hash "#"
  if (to === "#") {
    return <a>{children}</a>;
  }

  // Anchor only (e.g., "#id")
  if (to.startsWith("#")) {
    const fullAnchorPath = `${originalPath.replace(/\/$/, "")}/${to}`;

    return (
      <I18nLink
        to={fullAnchorPath}
        onClick={(e) =>
          (stripHash ? handleStrippedLinkClick : handleLinkClick)(
            to,
            e,
            true,
            onAnchorLinkClick,
          )
        }
        {...rest}
      >
        {children}
      </I18nLink>
    );
  }

  // Detect same-page anchor
  let isSamePage = false;

  if (isBrowser) {
    const currentPathWithoutLang =
      window.location.pathname.startsWith(`/${language}`) &&
      language !== defaultLanguage
        ? window.location.pathname.split(`/${language}`)[1]
        : window.location.pathname;

    isSamePage = currentPathWithoutLang === fullPath.split("#")[0];
  }

  if (hash) {
    return (
      <I18nLink
        to={fullPath}
        onClick={(e) =>
          (stripHash ? handleStrippedLinkClick : handleLinkClick)(
            fullPath,
            e,
            isSamePage,
            onAnchorLinkClick,
          )
        }
        {...rest}
      >
        {children}
      </I18nLink>
    );
  }

  return (
    <I18nLink to={fullPath.toLowerCase()} {...rest}>
      {children}
    </I18nLink>
  );
};
